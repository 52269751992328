<template>
  <iframe :src="src" class="frame" />
</template>

<script>
import { getToken } from '@/utils/auth'
import { getOpenWebUrl } from '@/api/login'

export default {
  data() {
    return {
      src: '',
      viewConfig: ''
    }
  },
  mounted: function () {
    this.getViewConfig()
  },
  methods: {
    getViewConfig() {
      getOpenWebUrl().then((res) => {
        this.viewConfig = res.data
        this.src = this.viewConfig + '/riskRecord/index?token=' + getToken()
      })
    }
  }
}
</script>

<style scoped></style>
